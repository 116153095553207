<template>
    <div class="order-wrapper">
        <div class="top-box">
            <span>我的卡</span>
            <el-button type="primary" size="small" @click="addCoupons">添加卡券</el-button>
        </div>

        <el-table :data="couponList" style="width: 100%" height="426px">
            <el-table-column prop="CreateTime" label="领取时间" width="180"></el-table-column>
            <el-table-column prop="Name" label="兑换码/口令"></el-table-column>
            <el-table-column prop="ActivationEndTime" width="180" label="有效期">
                <template slot-scope="scope">
                    {{ scope.row.ActivationEndTime && scope.row.ActivationEndTime.substr(0, 10) }}
                </template>
            </el-table-column>
            <el-table-column prop="GiveDuration" label="卡内容">
                <template slot-scope="scope">
                    {{ scope.row.GiveDuration }}天会员
                </template>
            </el-table-column>
            <el-table-column prop="State" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.State == 1">未使用</div>
                    <div v-if="scope.row.State == 2">已激活</div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" size="small" class="activation" @click="toUse(scope.row)"
                        v-if="scope.row.State == 1">激活使用</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 添加卡券弹窗 -->
        <el-dialog title="添加卡券" :visible.sync="addCouponDialog" width="410px" custom-class="visible-cancellation"
            :close-on-click-modal="false">
            <el-form>
                <el-form-item label="激活码：" :label-width="formLabelWidth">
                    <el-input v-model.trim="num" autocomplete="off" placeholder="请输入激活码/口令" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号：" :label-width="formLabelWidth">
                    <div>
                        {{
                            userInfo &&
                            userInfo.Phone &&
                            userInfo.Phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                        }}
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="login" @click="addCoupon">添加</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
  
<script>
import { GetUserWelfareLogs, ReceiveWelfare, ActivationWelfare } from "@/api";
import { mapGetters } from "vuex";

export default {
    name: "Order",
    computed: {
        ...mapGetters(["isLogin", "userInfo", "netBarId", "netBarName"])
    },
    data() {
        return {
            orderList: [],
            couponList: [],
            addCouponDialog: false,
            num: '',
            formLabelWidth: '80px'
        };
    },
    mounted() {
        this._GetUserWelfareLogs();
    },
    methods: {
        // 获取用户福利领取记录
        async _GetUserWelfareLogs() {
            let params = {
                userId: this.userInfo && this.userInfo.ConsumerId,
                pageIndex: 1,
                pageSize: 999,
                state: 0,
            };
            let res = await GetUserWelfareLogs(params);
            if (res.StatusCode == 200) {
                this.couponList = res.Items;
            }
        },
        // 添加卡券
        async addCoupon() {
            if (this.num) {
                let params = {
                    Phone: this.userInfo && this.userInfo.Phone,
                    Number: this.num
                }
                let res = await ReceiveWelfare(params)
                if (res.StatusCode == 200) {
                    this.$message({
                        message: "添加成功！",
                        type: "success",
                    });
                    this.addCouponDialog = false
                    this._GetUserWelfareLogs();
                }
            }
        },
        // 切换
        handleClick(e) {
            this.activeName = e.name;
            this.pageIndex = 1
            if (this.activeName == "1") {
                this._GetOrderList();
            }
            if (this.activeName == "2") {
                this._GetUserWelfareLogs();
            }
        },
        // 激活使用
        toUse(row) {
            this.$confirm(
                "确认激活使用蚂蚁特权卡券？",
                "提示：",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }
            )
                .then(async () => {
                    let params = {
                        welfareLogId: row.Id
                    }
                    let res = await ActivationWelfare(params)
                    if (res.StatusCode == 200) {
                        this.$message({
                            message: "激活成功！",
                            type: "success",
                        });
                        this.$store.dispatch("_GetUserInfo");
                        this._GetUserWelfareLogs();
                    }
                })
                .catch(() => { });
        },
        // 添加卡券
        addCoupons() {
            this.num = "";
            this.addCouponDialog = true
        }
    }
};
</script>
  
<style lang="scss" scoped>
.order-wrapper {
    height: 470px;
    // overflow: auto;
}

.top-box {
    height: 32px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include font_col3();

    span {
        position: relative;
        padding-left: 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 3px;
            background: $red_col;
        }
    }
}


.activation {
    color: $red_col;
    background: transparent !important;
}

.visible-cancellation {
    .el-button {
        width: 100%;
    }
}
</style>